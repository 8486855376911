import React, { FC, useMemo, useRef, useState } from "react";
import { IVideoPlayer } from "./VideoPlayer.interface";

import {
  ButtonStyled,
  ContainerStyled,
  ReplayIconWrapperStyled,
  VideoContainerStyled,
} from "./VideoPlayer.styled";
import { ReplayIcon } from "./ReplayIcon";
import { VideoJsPlayer } from "../VideoJsPlayer";
//@ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import { IVideoJsPlayer, VideoJsPlayerOptions } from "!video.js";

const VideoPlayer: FC<IVideoPlayer> = ({
  customThumbnailUrl,
  thumbnailUrl,
  videoDashUrl,
  videoMp4Url,
  // videoHlsUrl,
  videoUrl,
  width,
  height,
  color,
}) => {
  const isFirstPlay = useRef<any>(true);

  const [videoPlayer, setVideoPlayer] = useState<IVideoJsPlayer>();
  const [showReplayButton, setShowReplayButton] = useState(false);

  const options: VideoJsPlayerOptions = useMemo(() => {
    return {
      poster: customThumbnailUrl || thumbnailUrl,
      sources: [
        {
          src: videoDashUrl,
          type: "application/dash+xml",
        },
        // {
        //   src: videoHlsUrl,
        //   type: "application/x-mpegURL",
        // },
        {
          src: videoMp4Url,
          type: "video/mp4",
        },
        {
          src: videoUrl,
          type: "video/mp4",
        },
      ],
      autoplay: "muted"
    };
  }, [
    customThumbnailUrl,
    thumbnailUrl,
    videoDashUrl,
    // videoHlsUrl,
    videoMp4Url,
    videoUrl,
  ]);

  const extendedOnPlay = (player: IVideoJsPlayer) => {
    setShowReplayButton(false);

    // if (!isDesktop && isFirstPlay.current) {
    //   if (player.requestFullscreen) {
    //     player.requestFullscreen();
    //   } else if (player.webkitRequestFullscreen) {
    //     player.webkitRequestFullscreen();
    //   }
    // }

    window.parent.postMessage(
      {
        event_id: "tracking",
        data: {
          event: "play",
          currentTime: Math.ceil(player.currentTime()),
        },
      },
      "*"
    );

    if (isFirstPlay.current) {
      window.parent.postMessage(
        {
          event_id: "tracking",
          data: {
            event: "firstPlay",
          },
        },
        "*"
      );

      const videoWidth = player.videoWidth() || +(width || "0");
      const videoHeight = player.videoHeight() || +(height || "0");

      if (videoWidth < videoHeight) {
        const playerElement = document.querySelector(".video-js");
        window.parent.postMessage(
          {
            event_id: "tracking",
            data: {
              event: "animate",
            },
          },
          "*"
        );

        playerElement!.classList.add("vjs-animation");
      }

      isFirstPlay.current = false;
    }
  };

  const extendedOnPause = () => {
    window.parent.postMessage(
      {
        event_id: "tracking",
        data: {
          event: "pause",
        },
      },
      "*"
    );
  };

  const onReady = () => {
    window.parent.postMessage(
      {
        event_id: "custom",
        data: {
          event: "ready",
        },
      },
      "*"
    );
  };

  const extendedOnEnded = (player: IVideoJsPlayer) => {
    if (player.fullscreenElement) {
      player.exitFullscreen()
    }

    window.parent.postMessage(
      {
        event_id: "tracking",
        data: {
          event: "ended",
        },
      },
      "*"
    );

    setShowReplayButton(true);
  };

  const replayHandler = () => {
    setShowReplayButton(false);

    if (videoPlayer) {
      videoPlayer.currentTime(0);
      videoPlayer.play();
    }
  };

  const defaultPlayerActions = {
    onPlay: extendedOnPlay,
    onPause: extendedOnPause,
    onEnded: extendedOnEnded,
    onReady,
  };

  const replayButton = useMemo(() => {
    const colorToUse = color ? "#" + color : "$primary";

    if (showReplayButton) {
      return (
        <ButtonStyled onClick={replayHandler}>
          <ReplayIconWrapperStyled css={{ $$color: colorToUse }}>
            <ReplayIcon />
          </ReplayIconWrapperStyled>
        </ButtonStyled>
      );
    }

    return null;
    // eslint-disable-next-line
  }, [showReplayButton, color]);

  return (
    <ContainerStyled>
      <VideoContainerStyled>
        <VideoJsPlayer
          options={options}
          {...defaultPlayerActions}
          setPlayer={setVideoPlayer}
          color={color}
        />
      </VideoContainerStyled>

      {replayButton}
    </ContainerStyled>
  );
};

export default VideoPlayer;
