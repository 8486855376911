import React from "react";
import { globalStyles } from "@c3456/design-system";
import VideoPlayer from "./components/VideoPlayer/VideoPlayer";
import { useQuery } from "./utils/hooks/useQuery";
import { useLocation } from "react-router-dom";
import { IVideoPlayer } from "./components/VideoPlayer/VideoPlayer.interface";

function App() {
  const query = useQuery<IVideoPlayer>(useLocation().search);
  globalStyles();

  return <VideoPlayer {...query} />;
}

export default App;
