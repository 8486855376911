import { FC, useEffect, useRef } from "react";
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs, { VideoJsPlayerOptions } from "!video.js";
import "video.js/dist/video-js.css";
import { IVideoJsPlayer } from "./VideoJsPlayer.interface";

const initialOptions: VideoJsPlayerOptions = {
  controls: true,
  fluid: true,
  playsinline: true,
  preload: "metadata",
  controlBar: {
    pictureInPictureToggle: false,
  },
};

export const VideoJsPlayer: FC<IVideoJsPlayer> = ({
  options,
  onEnded,
  onPause,
  onPlay,
  onReady,
  setPlayer,
  color,
}) => {
  const videoRef = useRef<any>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered", "vjs-custom-styles");
      videoRef.current.appendChild(videoElement);

      const videoPlayer = videojs(videoElement, {
        ...initialOptions,
        ...options,
      });

      videoPlayer.on("ready", () => {
        const colorToUse = color ? "#" + color : "$primary";
        const playButton = document.querySelector<HTMLElement>(
          ".vjs-big-play-button"
        );

        if (playButton) {
          playButton.style.background = colorToUse;
          playButton.style.color = "#ffffff";
        }

        onReady();
      });

      videoPlayer.on("ended", () => {
        onEnded?.(videoPlayer);
        videoElement.classList.add("vjs-custom-ended");
      });

      videoPlayer.on("play", () => {
        onPlay?.(videoPlayer);
        videoElement.classList.remove("vjs-custom-ended");
      });

      videoPlayer.on("pause", () => {
        onPause?.();
      });

      playerRef.current = videoPlayer;
      setPlayer(videoPlayer);
    } else {
      const player = playerRef.current;

      player.autoplay(options.autoplay);
      player.src(options.sources);
    }
    // eslint-disable-next-line
  }, [options, videoRef]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player style={{ width: "100%" }}>
      <div ref={videoRef} />
    </div>
  );
};
