import { styled } from "@c3456/design-system";

export const ContainerStyled = styled("div", {
  position: "relative",
  zIndex: 1,
  height: "100%",
  width: "100%",
  objectFit: "cover",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  borderRadius: "10px 10px 0 0",
  backgroundColor: "$black",
});

export const ButtonStyled = styled("button", {
  position: "absolute",
  visibility: "visible",
  background: "$white",
  boxShadow: "$extraStrong",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  cursor: "pointer",
  borderRadius: "50%",
  border: "none",
  outline: "none",
  padding: "12px",

  "&:hover": {
    opacity: 0.9,
  },
});

export const ReplayIconWrapperStyled = styled("div", {
  display: "flex",

  "& svg": {
    width: "3em",
    height: "3em",
    fontSize: "24px",
    fill: "$$color",
  },
});

export const VideoContainerStyled = styled("div", {
  position: "relative",
  top: 0,
  maxWidth: "100%",
  maxHeight: "100%",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
