export function useQuery<T>(querystring: string): T {
  const params = new URLSearchParams(querystring);

  const obj = {};

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      // @ts-ignore
      obj[key] = params.getAll(key);
    } else {
      // @ts-ignore
      obj[key] = params.get(key);
    }
  }

  return obj as T;
}
